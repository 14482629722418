<template>
    <div>

        <form-wizard
            title="Confirmation"
            subtitle="Please complete all required input"
            stepSize="xs"
            @on-complete="submitWorkflow"
        >
            <tab-content
                title="Incomplete Transport"
                :before-change="validateForm.bind(this,'incomplete-transport')"
            >
                scascascasc
                <!-- <template v-for="d in non_log_transport">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <h4>Client: {{d.SRNo}} {{d.ClientName}}</h4>
                        </div>
                        <div
                            class="panel-body"
                            style="margin-bottom:10px"
                        >
                            <div class="">Transport ID: <b>{{d.TransportID}}</b></div>
                            <div class="">Sort: <b>{{d.Sort}}</b></div>
                            <div class="">Service: <b>{{d.Service}} ({{d.ServiceName}})</b></div>
                            <div class="">SRNo: <b>{{d.SRNo}} {{d.ClientName}}</b></div>
                            <div class="">Address:<br><b>{{d.Address}}</b></div>
                            <hr>
                            <div style="margin-bottom:5px">
                                <p>
                                    Was Client Picked Up ?
                                    <span v-if="d.is_client_picked_up!=null">{{d.is_client_picked_up}}</span>
                                    <Button
                                        type="button"
                                        v-if="d.is_client_picked_up!=null"
                                        @click="resetAction(d)"
                                        class="btn btn-sm btn-info"
                                    >
                                        <i class="fa fa-times"></i>
                                    </Button>
                                </p>
                            </div>
                            <div
                                class="row"
                                v-if="d.is_client_picked_up==null"
                            >
                                <div class="col-xs-6">
                                    <Button
                                        type="button"
                                        @click="setYes(d)"
                                        class="btn btn-success btn-block"
                                    ><i class="fa fa-check"></i> Yes</Button>
                                </div>
                                <div class="col-xs-6">
                                    <Button
                                        type="button"
                                        @click="setNo(d)"
                                        class="btn btn-danger btn-block"
                                    ><i class="fa fa-times"></i> No</Button>
                                </div>
                            </div>

                            <div
                                class=""
                                v-if="d.is_client_picked_up == 'no'"
                            >
                                <p>
                                    Did you drive to clients house?
                                    <template v-if="d.need_log==null">
                                        <Button
                                            type="button"
                                            @click="needLog(d,'yes')"
                                            class="btn btn-sm btn-primary"
                                        ><i class="fa fa-check"></i> Yes</Button>
                                        <Button
                                            type="button"
                                            @click="needLog(d,'no')"
                                            class="btn btn-sm btn-danger"
                                        ><i class="fa fa-times"></i> No</Button>
                                    </template>
                                    <template v-else>
                                        <span v-if="d.need_log!=null">{{d.need_log}}</span>
                                        <Button
                                            type="button"
                                            v-if="d.is_client_picked_up!=null"
                                            @click="resetNeedLog(d)"
                                            class="btn btn-sm btn-info"
                                        >
                                            <i class="fa fa-times"></i>
                                        </Button>
                                    </template>
                                <p><small>*YES, if arrived at client home, but client refuse to go</small></p>
                                <p><small>*NO, if do not go to client home</small></p>
                                <p><small>*If YES, please provide time reach client home</small></p>
                                <p><small>*Please proive some reason to detailing what happened</small></p>
                                </p>
                                <template v-if="d.need_log!=null">
                                    <select
                                        @change="onChangeReasonSelect($event,d)"
                                        v-model="d.reason_selection"
                                        class="form-control"
                                    >
                                        <option v-for="reason in reason_selection">
                                            {{ reason }}
                                        </option>
                                    </select>
                                    <textarea
                                        v-if="d.reason_selection=='Other Reason'"
                                        v-model="d.user_enter_remark"
                                        class="form-control remark"
                                        placeholder="Please put a remark detailing what happened"
                                    ></textarea>
                                </template>
                            </div>
                            <div
                                class=""
                                v-if="d.is_client_picked_up == 'yes' || d.need_log == 'yes'"
                                style="margin-top:5px;"
                            >
                                <MyTimepicker v-model="d.user_enter_time" />
                            </div>

                        </div>
                    </div>

                </template> -->

            </tab-content>

            <tab-content title="Summary">
                <h4>Transport need to Log ({{transport_sorted.length}})</h4>
                <hr>
                <template v-for="(d,k) in transport_sorted">
                    <v-panel :panel-success="d.isNewLog">
                        <template v-slot:panel-heading>
                            {{k+1}}. Client: {{d.SRNo}} {{d.ClientName}}
                            <span
                                v-show="d.isNewLog"
                                class="badge badge-secondary"
                            >New</span>
                        </template>
                        <template v-slot:panel-body>
                            <template v-if="d.isNewLog==true">
                                <div>Is Client Picked Up: <b>{{d.is_client_picked_up}}</b></div>
                                <div v-show="d.is_client_picked_up=='no'">Reason: <b>{{d.user_enter_remark}}</b></div>
                                <div>Logged Time: <b>{{d.user_enter_time}}</b></div>
                            </template>
                            <template v-else>
                                <div>Is Client Picked Up: <b>{{d.TransportIsPickedUp}}</b></div>
                                <div v-show="d.TransportIsPickedUp=='0'">Reason: <b>{{d.TransportNonPickupReason}}</b></div>
                                <div>Logged Time: <b>{{d.LogTime}}</b></div>
                            </template>
                        </template>
                    </v-panel>
                </template>

                <h4>Transport no need to Log ({{non_complete_transport.length}})</h4>
                <hr>
                <template v-for="d in non_complete_transport">
                    <v-panel :panel-danger="true">
                        <template v-slot:panel-heading>
                            Client: {{d.SRNo}} {{d.ClientName}}
                            <span
                                v-show="d.isNewLog"
                                class="badge badge-secondary"
                            >New</span>
                        </template>
                        <template v-slot:panel-body>
                            <template v-if="d.isNewLog==true">
                                <div class="">Reason:<br><b>{{d.user_enter_remark}}</b></div>
                            </template>
                            <template v-else>
                                <div class="">Reason:<br><b>{{d.AdditionalRemark}}</b></div>
                            </template>
                        </template>
                    </v-panel>
                </template>

                Confirm to submit ?
            </tab-content>

        </form-wizard>
    </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
export default {
    components: {
        FormWizard,
        TabContent,
    },
    data() {
        return {
            table: "123",
            id: "123",

            non_log_transport: [],
            log_transport: [],
            non_complete_transport: [],
            skip_log_transport: [],

            latitude: null,
            longitude: null,

            reason_selection: [
                "Consumer not contactable",
                "Consumer not feeling well",
                "Transport cancelled",
                "Other Reason",
            ],
        }
    },
    computed: {
        transport_sorted() {
            var $vm = this
            let arr = []
            let incomplete_arr = []

            $vm.non_log_transport.forEach(function (v, k) {
                v.isNewLog = true
                if (v.is_client_picked_up == "yes" || v.need_log == "yes") {
                    v.LogTime = v.user_enter_time
                    arr.push(v)
                } else {
                    incomplete_arr.push(v)
                }
            })
            $vm.log_transport.forEach(function (v, k) {
                v.isNewLog = false
                arr.push(v)
            })
            $vm.skip_log_transport.forEach(function (v, k) {
                v.isNewLog = false
                incomplete_arr.push(v)
            })

            arr = arr.sort(function (a, b) {
                if (a.LogTime < b.LogTime) {
                    return -1
                } else {
                    return 1
                }
            })

            $vm.non_complete_transport = incomplete_arr
            return arr
        },
    },
    methods: {
        onChangeReasonSelect($event, transport) {
            let reason = $event.target.value
            if (reason == "Other Reason") {
                transport.user_enter_remark = ""
            } else {
                transport.user_enter_remark = reason
            }
        },
        setYes(transport) {
            Vue.set(transport, "user_enter_time", "")
            Vue.set(transport, "is_client_picked_up", "yes")
        },
        setNo(transport) {
            Vue.set(transport, "user_enter_remark", "")
            Vue.set(transport, "is_client_picked_up", "no")
        },
        resetAction(transport) {
            Vue.set(transport, "is_client_picked_up", null)
        },
        needLog(transport, v) {
            Vue.set(transport, "need_log", v)
        },
        resetNeedLog(transport) {
            Vue.set(transport, "need_log", null)
        },
        validateForm(type) {
            if (type == "incomplete-transport") {
                let isValid = true
                this.non_log_transport.forEach(function (v, k) {
                    let client_no = v.SRNo
                    if (v.is_client_picked_up == null) {
                        alert("Action haven't choose for Client " + client_no)
                        isValid = false
                    } else {
                        if (
                            v.is_client_picked_up == "yes" &&
                            (v.user_enter_time == null ||
                                v.user_enter_time == "")
                        ) {
                            alert("Time haven't enter for Client " + client_no)
                            isValid = false
                        }
                        if (
                            v.is_client_picked_up == "no" &&
                            (v.user_enter_remark == null ||
                                v.user_enter_remark == "")
                        ) {
                            alert(
                                "Remark haven't enter for Client " + client_no
                            )
                            isValid = false
                        }
                    }
                })
                return isValid
            } else {
                return true
            }
        },

        getCoordinate() {
            var $vm = this
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    $vm.latitude = position.coords.latitude
                    $vm.longitude = position.coords.longitude
                })
            }
        },
        submitWorkflow() {},
    },
    created() {
        //this.getTransportAssignment()
        //this.getCoordinate()
    },
}
</script>
