require('./bootstrap');

/*
|----------------------------------------------------------------
| Vue 2
|----------------------------------------------------------------
*/

import { App, plugin } from '@inertiajs/inertia-vue'
import Vue from 'vue'

Vue.use(plugin)
import AppLayout from "./Layouts/AppLayout"
Vue.component('AppLayout', AppLayout)

import MyTimepicker from './Components/Common/Timepicker'
Vue.component('MyTimepicker', MyTimepicker)

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

import store from './Stores/index'

const el = document.getElementById('app')
new Vue({
    store: store,
    render: h => h(App, {
        props: {
            initialPage: JSON.parse(el.dataset.page),
            resolveComponent: name => require(`./Pages/${name}`).default,
        },
    }),
}).$mount(el)