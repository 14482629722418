<template>
    <AppLayout>
        <div class="h3">Assignment Check Out</div>
        <div class="h3">Customer Name (Customer No)</div>
        <!-- <CheckoutTransport></CheckoutTransport> -->
        <CheckoutHomeHelp :job_batch_id="job_batch_id"></CheckoutHomeHelp>
    </AppLayout>
</template>
<script>
import CheckoutTransport from "../Components/CheckoutTransport"
import CheckoutHomeHelp from "../Components/CheckoutHomeHelp"
export default {
    components: { CheckoutTransport, CheckoutHomeHelp },
    props: ["job_batch_id"],
    created() {},
}
</script>