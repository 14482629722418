<template>
    <div class="">

        <form-wizard
            title="Client Workflow"
            subtitle="Please complete all required input"
            stepSize="xs"
            @on-complete="submitWorkflow"
        >
            <tab-content
                v-for="(workflow, index) in workflows"
                :title="workflow.workflow_title"
                :before-change="validateForm.bind(this,workflow.id)"
                :key="index"
            >
                <template v-if="workflow.id == 1">
                    <client-signature-form v-model="client_signature"></client-signature-form>
                </template>
                <template v-else-if="workflow.id == 2">
                    <client-feedback-form
                        :rating="client_rating"
                        :rating-text="client_rating_text"
                        @rating-text-change="client_rating_text = $event"
                        @rating-change="client_rating=$event"
                    ></client-feedback-form>
                </template>
                <template v-else-if="workflow.id == 3">
                    <client-image-form v-model="client_image"></client-image-form>
                </template>
                <template v-else-if="workflow.id == 4">
                    <communication-book-record v-model="communication_book_record"></communication-book-record>
                </template>
                <template v-else>
                    Undefine Workflow
                </template>
            </tab-content>

            <tab-content title="Summary">
                Confirm to submit ?
            </tab-content>

        </form-wizard>
    </div>

</template>


<script>
import { FormWizard, TabContent } from "vue-form-wizard"
import "vue-form-wizard/dist/vue-form-wizard.min.css"

import ClientImageForm from "../Components/Common/ClientImageForm"
import CommunicationBookRecord from "../Components/Common/CommunicationBookRecord"
import FeedbackForm from "../Components/Common/FeedbackForm"
import SignatureBox from "../Components/Common/SignatureBox"

export default {
    components: {
        FormWizard,
        TabContent,
        "client-image-form": ClientImageForm,
        "communication-book-record": CommunicationBookRecord,
        "client-feedback-form": FeedbackForm,
        "client-signature-form": SignatureBox,
    },
    props: ["job_batch_id"],
    data() {
        return {
            client_rating: null, // is integer
            client_rating_text: null, // is string
            client_image: null, // is form image
            client_signature: null, // is data url
            communication_book_record: null, // is form image
            latitude: null,
            longitude: null,
            //TODO Sync from database instead of hardcoded
            workflows: [
                { id: 1, workflow_title: "Client Signature" },
                // { id: 2, workflow_title: "Client Feedback" },
                // { id: 3, workflow_title: "Client Image" },
                // { id: 4, workflow_title: "Communication Book Record" },
            ],
        }
    },
    methods: {
        //TODO complete the function
        getWorkflowItem() {
            axios
                .get("/assignment/axios_get_client_workflow", {
                    params: {
                        client_no: CLIENT_NO,
                    },
                })
                .then((res) => {
                    this.workflows = res.data
                })
        },
        //TODO customized validation based on workflow set
        validateForm(workflowId) {
            if (workflowId == 1) {
                if (this.client_signature == null) {
                    Swal.fire("Signature is required", "", "error")
                    return false
                }
            } else if (workflowId == 2) {
                if (this.client_rating == null) {
                    Swal.fire("Client Rating is required", "", "error")
                    return false
                }
            } else if (workflowId == 3) {
                if (this.client_image == null) {
                    Swal.fire("Client Image is required", "", "error")
                    return false
                }
            } else {
                return true
            }
            return true
        },

        submitWorkflow() {
            let job_batch_id = this.job_batch_id

            var formData = new FormData()
            var rating = this.client_rating
            var rating_text = this.client_rating_text
            var client_image = this.client_image
            var communication_book_record = this.communication_book_record
            var signature = this.client_signature
            var latitude = this.latitude
            var longitude = this.longitude

            formData.append("job_batch_id", job_batch_id)

            formData.append("rating", rating)
            formData.append("rating_text", rating_text)
            formData.append("client_image", client_image)
            formData.append(
                "communication_book_record",
                communication_book_record
            )
            formData.append("signature", signature)
            formData.append("latitude", latitude)
            formData.append("longitude", longitude)

            this.$store.commit("showLoading")

            axios
                .post(
                    "/worker/jobs/" + this.job_batch_id + "/end_job",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then((res) => {
                    console.log(res)
                    if (res.data == "success") {
                        this.$swal(
                            "Success",
                            "Assignment has been marked as completed, this page will refresh soon",
                            "success"
                        ).then((res) => {
                            location.href = "/worker/jobs/" + job_batch_id
                        })
                    } else {
                        this.$swal(
                            "Error",
                            "It seen there is some issues in backend. Please give feedback to Dev Team",
                            "error"
                        )
                    }
                })
                .then((res) => {
                    this.$store.commit("hideLoading")
                })
        },
        getCoordinate() {
            var $vm = this
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    $vm.latitude = position.coords.latitude
                    $vm.longitude = position.coords.longitude
                })
            }
        },
    },
    created() {
        //TODO customizable Workflow for end job
        //this.getWorkflowItem()
        this.getCoordinate()
    },
}
</script>
