<template>
    <div>
        <div class="col-md-12">Signature</div>
        <center>
            <canvas
                id="singatureCanvas"
                style="border:1px solid black;"
                :class="{signature_confirmed : confirmed}"
            ></canvas>
            <br>
            <div
                class="col-md-12"
                style="margin-top:20px"
            >
                <button
                    v-if="!confirmed"
                    type="button"
                    class="btn btn-success"
                    @click="confirm"
                >Confirm</button>
                <button
                    type="button"
                    class="btn btn-danger"
                    @click="clear"
                >Clear</button>
            </div>
        </center>
    </div>
</template>


<script>
import SignaturePad from "signature_pad"
export default {
    data() {
        return {
            signaturePad: {},
            confirmed: false,
        }
    },
    methods: {
        draw() {
            let canvas = document.getElementById("singatureCanvas")
            this.signaturePad = new SignaturePad(canvas)
        },
        clear() {
            this.signaturePad.clear()
            this.signaturePad.on()
            this.confirmed = false
            this.$emit("input", null)
        },
        confirm() {
            if (this.signaturePad.isEmpty()) {
                alert("Signature cannot be empty")
                return
            } else {
                this.signaturePad.off()
                this.confirmed = true
            }
            let data = this.signaturePad.toDataURL()
            this.$emit("input", data)
        },
    },
    mounted() {
        this.draw()
    },
    created() {},
}
</script>
