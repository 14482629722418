<template>
    <div>
        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="true"
        ></loading>
        <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm">
            <div class="container">
                <a class="navbar-brand">
                    {{app_name}}
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div
                    class="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <!-- Left Side Of Navbar -->
                    <ul class="navbar-nav mr-auto">

                    </ul>

                    <!-- Right Side Of Navbar -->
                    <ul class="navbar-nav ml-auto">

                        <li class="nav-item dropdown">
                            <a
                                id="navbarDropdown"
                                class="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {{user_name}}
                            </a>

                            <div
                                class="dropdown-menu dropdown-menu-right"
                                aria-labelledby="navbarDropdown"
                            >
                                <a class="dropdown-item">
                                    Logout
                                </a>

                                <form
                                    id="logout-form"
                                    method="POST"
                                    class="d-none"
                                >
                                </form>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <main class="py-4 container">
            <slot></slot>
        </main>

    </div>
</template>

<script>
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"

export default {
    components: {
        Loading,
    },
    data() {
        return {
            app_name: "-",
            user_name: "-",
        }
    },
    computed: {
        isLoading() {
            return this.$store.state.isLoading
        },
    },
    created() {
        axios.get("/get_app_layout_data").then((res) => {
            let data = res.data
            this.app_name = data.app_name
            this.user_name = data.user_name
        })
    },
}
</script>

